<template>
  <div class="analyse-table store-table-container">
    <div class="flex-btw">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="园区汇总" name="1"></el-tab-pane>
        <el-tab-pane label="门票汇总" name="3"></el-tab-pane>
        <el-tab-pane label="园区明细" name="2"></el-tab-pane>
      </el-tabs>

      <!-- 导出按钮 -->
      <el-tooltip class="item" effect="dark" content="导出" placement="left">
        <span class="border-icon" @click="handleExport">
          <i class="iconfont icon-a-lujing207"></i></span>
      </el-tooltip>
    </div>
    <!-- 园区汇总 -->
    <template v-if="activeName === '1'">
      <el-table :data="summaryTableData" v-loading="loading" :height="'calc(100vh - 304px)'" style="width: 100%"
        class="summary-table" :summary-method="getActivitySummaries" show-summary>
        <el-table-column prop="name" label="园区名称" width="350">
          <template #default="scope">
            <span class="text-black">
              {{ scope.row.name }}
            </span>
          </template>
        </el-table-column>

        <el-table-column label="金额(元)">
          <el-table-column prop="pay_money" label="销售金额">
            <template #default="scope">
              <span class="text-theme">
                {{ scope.row.pay_money }}
              </span>
            </template>
          </el-table-column>

          <el-table-column prop="refund_money" label="退款金额">
            <template #default="scope">
              <span class="text-red">
                {{ scope.row.refund_money }}
              </span>
            </template>
          </el-table-column>

          <el-table-column prop="income_money" label="实收金额">
            <template #default="scope">
              <span class="text-orange">
                {{ scope.row.income_money }}
              </span></template>
          </el-table-column>
        </el-table-column>
        <el-table-column label="统计周期">
          <el-table-column prop="start" label="开始时间">
            <template #default="scope">
              <span class="text-grey">
                {{ scope.row.start }}
              </span></template></el-table-column>
          <el-table-column prop="end" label="截止时间">
            <template #default="scope">
              <span class="text-grey">
                {{ scope.row.end }}
              </span></template></el-table-column>
        </el-table-column>
      </el-table>
    </template>

    <!-- 园区明细 -->
    <template v-if="activeName === '2'">
      <el-table v-loading="loading" :data="tableData" tooltip-effect="light" stripe :height="'calc(100vh - 416px)'"
        style="width: 100%">
        <el-table-column property="order_sn" label="订单编号">
          <template #default="scope">
            <span class="text-grey">
              {{ scope.row.order_sn }}
            </span></template>
        </el-table-column>
        <el-table-column property="name" label="门票名称">
          <template #default="scope">
            <span class="text-grey">
              {{ scope.row.name }}
            </span></template>
        </el-table-column>
        <el-table-column property="order_type" label="发生类型">
          <template #default="scope">
            <span :class="scope.row.order_type == 1 ? 'text-theme' : 'text-red'">
              {{ ["", "收入", "支出"][scope.row.order_type] }}
            </span>
          </template>
        </el-table-column>
        <el-table-column property="money" label="门票售价">
          <template #default="scope">
            <span class="text-theme">
              {{ scope.row.money }}
            </span>
          </template>
        </el-table-column>
        <el-table-column property="payway_name" label="支付方式">
          <template #default="scope">
            <span class="text-grey">
              {{ scope.row.payway_name }}
            </span></template>
        </el-table-column>
        <el-table-column property="order_time" label="支付时间">
          <template #default="scope">
            <span class="text-grey">
              {{ scope.row.order_time }}
            </span></template>
        </el-table-column>
      </el-table>

      <div class="scenic-statistics-container">
        <div></div>
        <div class="statistics-flex">
          <div class="statistics-item">
            <span class="label badge-label">销售金额：</span>
            <span class="number text-theme">￥{{ roundNumber(statisticsData.pay_money) }}
            </span>
          </div>
          <div class="statistics-item">
            <span class="label badge-label">退款金额：</span>
            <span class="number text-red">￥{{ roundNumber(statisticsData.refund_money) }}
            </span>
          </div>
          <div class="statistics-item">
            <span class="label badge-label">实收金额：</span>
            <span class="number text-orange">
              ￥{{
                roundNumber(
                  roundNumber(statisticsData.pay_money) -
                    roundNumber(statisticsData.refund_money)
                )
              }}
            </span>
          </div>
        </div>
      </div>

      <div class="pagination-container">
        <el-pagination v-model:currentPage="listQuery.page" v-model:page-size="listQuery.limit"
          :page-sizes="[20, 50, 100, 200, 500]" background layout="total, sizes, prev, pager, next, jumper"
          :total="listQuery.total" @size-change="handleSizeChange" @current-change="handleCurrentPageChange">
        </el-pagination>
      </div>
    </template>

    <!-- 门票汇总 -->
    <template v-if="activeName === '3'">
      <el-table :data="ticketTableData" v-loading="loading" :height="'calc(100vh - 304px)'" style="width: 100%"
        class="summary-table" :summary-method="getActivitySummaries" show-summary>
        <el-table-column prop="name" label="门票名称" width="350">
          <template #default="scope">
            <span class="text-black">
              {{ scope.row.name }}
            </span>
          </template>
        </el-table-column>

        <el-table-column label="金额(元)">
          <el-table-column prop="pay_money" label="销售金额">
            <template #default="scope">
              <span class="text-theme">
                {{ scope.row.pay_money }}
              </span>
            </template>
          </el-table-column>

          <el-table-column prop="refund_money" label="退款金额">
            <template #default="scope">
              <span class="text-red">
                {{ scope.row.refund_money }}
              </span>
            </template>
          </el-table-column>

          <el-table-column prop="income_money" label="实收金额">
            <template #default="scope">
              <span class="text-orange">
                {{ scope.row.income_money }}
              </span>
            </template>
          </el-table-column>
        </el-table-column>

        <el-table-column label="统计周期">
          <el-table-column prop="start" label="开始时间">
            <template #default="scope">
              <span class="text-grey">
                {{ scope.row.start }}
              </span>
            </template>
          </el-table-column>

          <el-table-column prop="end" label="截止时间">
            <template #default="scope">
              <span class="text-grey">
                {{ scope.row.end }}
              </span>
            </template>
          </el-table-column>
        </el-table-column>
      </el-table>
    </template>
  </div>
</template>

<script>
  import { ref, watch, nextTick } from "vue";
  import { AnalyseApi } from "@/plugins/api.js";
  import { ElMessage } from "element-plus";
  import { exportExcel, print } from "@/utils/common.js";

  export default {
    props: {
      queryData: {
        type: Object,
        default: async () => { },
      },
      echartClick: {
        type: Boolean,
        default: false,
      },
    },
    emits: ["showSelect"],
    setup(props, { emit }) {
      const sendParmas = ref({});
      watch(
        () => props.queryData,
        (data) => {
          const lastData = JSON.parse(JSON.stringify(data));
          const parmas = {
            stime: lastData.stime,
            etime: lastData.etime,
            payway: lastData.payway,
            s_id: lastData.scenicId,
            st_id: lastData.st_id,
          };
          sendParmas.value = parmas;
          nextTick(() => {
            props.echartClick ? (activeName.value = "2") : false;
            emit("showSelect", activeName.value == "2" ? true : false, activeName.value);
            fetchData(activeName.value);
          });
        },
        {
          deep: true,
          immediate: true,
        }
      );
      const listQuery = ref({
        page: 1,
        limit: 20,
        total: 0,
      });

      const tableData = ref([]);
      const statisticsData = ref([]);
      const summaryTableData = ref([]);
      const ticketTableData = ref([]);

      const loading = ref(false);
      function fetchData(type) {
        loading.value = true;
        type === "1" &&
          AnalyseApi.getScenicSummary({
            search_type: 3,
            ...sendParmas.value,
          }).then((res) => {
            loading.value = false;
            if (res.Code === 200) {
              summaryTableData.value = res.Data || [];
            } else {
              let msg = res.Message ? res.Message : "获取数据失败！";
              ElMessage.error(msg);
            }
          });
        type === "2" &&
          AnalyseApi.getScenicSummary({
            ...sendParmas.value,
            page: listQuery.value.page,
            limit: listQuery.value.limit,
            search_type: 2,
          }).then((res) => {
            loading.value = false;
            if (res.Code === 200) {
              tableData.value = res.Data.list;
              statisticsData.value = res.Data.total;
              listQuery.value.total = res.Data.total.num;
            } else {
              let msg = res.Message ? res.Message : "获取数据失败！";
              ElMessage.error(msg);
            }
          });
        type === "3" &&
          AnalyseApi.getScenicSummary({
            search_type: 4,
            ...sendParmas.value,
          }).then((res) => {
            loading.value = false;
            if (res.Code === 200) {
              ticketTableData.value = res.Data || [];
            } else {
              let msg = res.Message ? res.Message : "获取数据失败！";
              ElMessage.error(msg);
            }
          });
      }


      function roundNumber(num) {
        return Math.round(+num * 100) / 100;
      }
      function handleSizeChange(val) {
        listQuery.value.limit = val;
        fetchData(activeName.value);
      }
      function handleCurrentPageChange(p) {
        listQuery.value.page = p;
        fetchData(activeName.value);
      }
      const activeName = ref("1");
      function handleClick(tab) {
        emit("showSelect", tab.props.name == "2" ? true : false, tab.props.name);
        fetchData(tab.props.name);
      }
      function getTime(data, type) {
        // type 1最小 2最大
        let newArr = data.map((ele) => {
          return new Date(ele).getTime();
        });
        let minIndex = 0;
        let minDate = newArr[0];
        let maxIndex = 0;
        let maxDate = newArr[0];
        newArr.forEach((ele, index) => {
          if (ele < minDate) {
            minDate = ele;
            minIndex = index;
          }
          if (ele > maxDate) {
            maxDate = ele;
            maxIndex = index;
          }
        });

        return data[type === 1 ? minIndex : maxIndex];
      }
      const getActivitySummaries = (param) => {
        const { columns, data } = param;
        const sums = [];
        columns.forEach((column, index) => {
          if (index === 0) {
            sums[index] = "合计";
            return;
          } else if (index === 4) {
            const timeData = data.map((item) => item[column.property]);
            sums[index] = getTime(timeData, 1);
          } else if (index === 5) {
            const timeData = data.map((item) => item[column.property]);
            sums[index] = getTime(timeData, 2);
          } else {
            const values = data.map((item) => Number(item[column.property]));
            if (!values.every((value) => isNaN(value))) {
              sums[index] = values.reduce((prev, curr) => {
                const value = Number(curr);
                if (!isNaN(value)) {
                  return roundNumber(prev + curr);
                } else {
                  return roundNumber(prev);
                }
              }, 0);
              sums[index] += "";
            } else {
              sums[index] = "-";
            }
          }
        });

        return sums;
      };
      // 园区汇总
      const summaryPropertiesConfig = ref([
        { field: "name", displayName: "园区名称" },
        { field: "pay_money", displayName: "金额(元)-销售金额" },
        { field: "refund_money", displayName: "金额(元)-退款金额" },
        { field: "income_money", displayName: "金额(元)-实收金额" },
        { field: "start", displayName: "统计周期-开始时间" },
        { field: "end", displayName: "统计周期-截止时间" },
      ]);
      // 园区明细
      const propertiesConfig = ref([
        { field: "order_sn", displayName: "订单编号" },
        { field: "name", displayName: "门票名称" },
        { field: "typeText", displayName: "发生类型" },
        { field: "money", displayName: "门票售价" },
        { field: "payway_name", displayName: "支付方式" },
        { field: "order_time", displayName: "支付时间" },
      ]);
      // 门票汇总
      const admissionFeeConfig = ref([
        { field: "name", displayName: "门票名称" },
        { field: "pay_money", displayName: "金额(元)-销售金额" },
        { field: "refund_money", displayName: "金额(元)-退款金额" },
        { field: "income_money", displayName: "金额(元)-实收金额" },
        { field: "start", displayName: "统计周期-开始时间" },
        { field: "end", displayName: "统计周期-截止时间" },
      ]);
      function processData(data) {
        data.length &&
          data.forEach((el) => {
            el.typeText = ["", "收入", "支出"][el.order_type];
          });
        return data;
      }
      function getLastData() {
        let title = "";
        let data = null;
        let config = null;
        if (activeName.value === "1") {
          title = "园区汇总";
          data = summaryTableData.value;
          config = summaryPropertiesConfig.value;
        } else if (activeName.value === "2") {
          title = "园区明细";
          data = parkDetails.value;
          config = propertiesConfig.value;
        } else {
          title = "门票汇总";
          data = ticketTableData.value;
          config = admissionFeeConfig.value;
        }
        return [title, data, config];
      }
      function handlePrint() {
        print(getLastData()[0], processData(getLastData()[1]), getLastData()[2]);
      }
      const parkDetails = ref([]);  // 园区明细导出数据
      const getExportDatas = () => {
        AnalyseApi.getScenicSummary({
          ...sendParmas.value,
          page: 1,
          limit: 10000000,
          search_type: 2,
        }).then((res) => {
          loading.value = false;
          if (res.Code === 200) {
            parkDetails.value = res.Data.list ? res.Data.list : [];
            try {
              exportExcel(
                getLastData()[0],
                processData(getLastData()[1]),
                getLastData()[2]
              );
              ElMessage.success({
                message: "导出成功！",
              });
            } catch (e) {
              ElMessage.error({
                message: "导出失败！",
              });
            }
          } else {
            ElMessage.error(res.Message);
          }
        });
      }
      function handleExport() {
        if (activeName.value == '2') {
          getExportDatas();
        } else {
          try {
            exportExcel(
              getLastData()[0],
              getLastData()[1],
              getLastData()[2]
            );
            ElMessage.success({
              message: "导出成功！",
            });
          } catch (e) {
            ElMessage.error({
              message: "导出失败！",
            });
          }
        }
      }
      return {
        tableData,
        loading,
        handleSizeChange,
        handleCurrentPageChange,
        listQuery,
        handleExport,
        handlePrint,
        statisticsData,
        roundNumber,
        activeName,
        handleClick,
        summaryTableData,
        getActivitySummaries,
        getTime,
        ticketTableData,
        parkDetails,
        getExportDatas,
      };
    },
  };
</script>

<style lang="scss">
  .scenic-statistics-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: var(--bg-content-color);
    padding: 20px 40px 20px 0;

    >span {
      color: var(--error-color);
    }

    .statistics-flex {
      display: flex;

      .statistics-item {
        display: flex;
        align-items: center;
        margin-right: 40px;

        .badge-label {
          margin-right: 10px;
        }

        .label {
          color: #999999;
          position: relative;

          .badge {
            font-size: 10px;
            display: inline-block;
            position: absolute;
            right: -10px;
            top: -4px;
          }
        }

        .number {
          color: var(--text-color);
          font-size: 20px;
        }
      }
    }
  }
</style>
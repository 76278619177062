<!-- 园区汇总 -->

<template>
  <div class="main-cnt statement-container">
    <div class="flex-btw">
      <div class="flex  flex-shrink">
        <el-select v-model="formData.scenicId" placeholder="请选择园区">
          <el-option :label="item.s_name" :value="item.s_id" v-for="item in scenicOptions" :key="item.s_id"></el-option>
        </el-select>

        <div class="flex" style="line-height: 40px;">
          <div class="s-m-l-r flex-shrink" style="color:var(--top-text-color);">支付时间</div>
          <el-date-picker v-model="formData.paymentTime" type="daterange" :clearable="false" placeholder="请选择支付时间"
            value-format="YYYY-MM-DD" range-separator="-" start-placeholder="支付时间开始" end-placeholder="支付时间结束"
            @change="paymentTimeChange">
          </el-date-picker>
        </div>

        <el-select class="s-m-l-r" v-if="isTicketOptions" v-model="queryData.st_id" multiple collapse-tags clearable
          placeholder="请选择门票">
          <el-option :label="item.st_name" :value="item.st_id" v-for="item in spotOptions" :key="item.st_id">
          </el-option>
        </el-select>

        <el-select class="s-m-l-r" v-if="isTableDetail" v-model="queryData.payway" multiple clearable collapse-tags
          placeholder="请选择支付方式">
          <el-option :label="item.orp_name" :value="item.orp_id" v-for="item in orderTypeOptions" :key="item.orp_id">
          </el-option>
        </el-select>
        <el-button class="s-m-l-r" type="primary" plain round @click="handleReset" style="height: 40px;">重置</el-button>
      </div>
      <div>
        <div class="flex" style="background-color: var(--theme-bg-color);border-radius: 20px;">
          <div v-for="(item, i) in modeTabs" :key="i">
            <div style="padding: 10px 35px;" :class="active == i ? 'tabActive':'tabs'" @click="onClickTab(i)">
              {{item.name }}
            </div>
          </div>
        </div>
        <!-- <el-button type="primary" @click="handleQuery">{{showEchart ? "明细" : "统计"}}</el-button> -->
      </div>
    </div>

    <!-- <el-row :gutter="10">
      <el-col :span="4">
        <el-select v-model="formData.scenicId" placeholder="请选择园区">
          <el-option :label="item.s_name" :value="item.s_id" v-for="item in scenicOptions" :key="item.s_id"></el-option>
        </el-select>
      </el-col>
      <el-col :span="6">
        <el-row>
          <el-col :span="5" class="text-center">
            <span>支付时间</span>
          </el-col>
          <el-col :span="9">
            <el-date-picker v-model="formData.paymentTime" type="daterange" :clearable="false" placeholder="请选择支付时间"
              value-format="YYYY-MM-DD" range-separator="-" start-placeholder="支付时间开始" end-placeholder="支付时间结束"
              @change="paymentTimeChange">
            </el-date-picker>
          </el-col>
        </el-row>
      </el-col>
      <el-col :span="4">
        <el-select v-if="isTableDetail" v-model="queryData.spotId" multiple collapse-tags clearable placeholder="请选择景点"
          :disabled="!formData.scenicId ? true : false">
          <el-option :label="item.name" :value="item.keyValue" v-for="item in spotOptions" :key="item.keyValue">
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="3">
        <el-select v-if="isTableDetail" v-model="queryData.payway" multiple clearable collapse-tags
          placeholder="请选择支付方式">
          <el-option :label="item.orp_name" :value="item.orp_id" v-for="item in orderTypeOptions" :key="item.orp_id">
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="5" class="action-btn">
        <template v-if="!showEchart">
          <el-tooltip class="item" effect="dark" content="导出" placement="left">
            <span class="border-icon" @click="handleExport">
              <i class="iconfont icon-a-lujing207"></i></span>
          </el-tooltip>

          <el-tooltip class="item" effect="dark" content="打印" placement="left">
            <span class="border-icon mr-10" @click="handlePrint">
              <i class="iconfont icon-a-lujing206"></i></span>
          </el-tooltip>
          <el-button @click="handleReset">重置</el-button>
        </template>
        <el-button type="primary" @click="handleQuery">{{
          showEchart ? "明细" : "统计"
        }}</el-button>
      </el-col>
    </el-row> -->
    <div class="content">
      <!-- 统计表 -->
      <ScenicEcharts v-if="showEchart" :queryData="lastEchartQuery" @paywayClick="paywayClick" @spotClick="spotClick">
      </ScenicEcharts>

      <!-- 明细 -->
      <ScenicTable v-if="!showEchart" ref="scenicTableRef" :echartClick="echartClick" @showSelect="showSelect"
        :queryData="lastTableQuery"></ScenicTable>
    </div>
  </div>
</template>
<script>
  import { ref, onMounted, reactive, watch, computed } from "vue";
  import { BasicApi } from "@/plugins/api.js";
  import { ElMessage } from "element-plus";
  import ScenicEcharts from "../components/ScenicEcharts.vue";
  import ScenicTable from "../components/ScenicTable.vue";
  import dayjs from "dayjs";
  export default {
    components: {
      ScenicEcharts,
      ScenicTable,
    },
    setup() {
      const formData = reactive({  // 筛选条件
        scenicId: "",  // 园区
        paymentTime: [dayjs().format("YYYY-MM-DD"), dayjs().format("YYYY-MM-DD")],  // 支付时间
        stime: '',
        etime: '',
      });
      const modeTabs = ref([{ id: 1, name: '统计' }, { id: 1, name: '明细' },]);  // 模式
      const active = ref(0);  // tab下标
      const scenicOptions = ref([]);  // 园区选项
      const spotOptions = ref([]);  // 门票选项
      const orderTypeOptions = ref([]);  // 支付方式选项
      const queryData = ref({  // 明细筛选条件
        st_id: [],  // 门票
        payway: [],  // 支付方式
      });
      const showEchart = ref(true);  // 显示统计表或明细
      const isTableDetail = ref(false); //是否为明细表
      const isTicketOptions = ref(false);  // 是否显示门票筛选
      /**
       * 
       * 切换统计、明细
       * 
       * **/
      const onClickTab = (i) => {
        active.value = i;
        isTicketOptions.value = false;
        isTableDetail.value = false;
        echartClick.value = false;
        showEchart.value = i == '0';
        if (showEchart.value) {
          queryData.value = {
            st_id: [],
            payway: [],
          };
        }
      }
      /**
       * 
       * 获取园区列表数据
       * 
       * **/
      const getAllScenicData = () => {
        BasicApi.getAllScenicData({ auth: '1' }).then((res) => {
          if (res.Code === 200) {
            scenicOptions.value = res.Data ? res.Data : [];
            formData.scenicId = res.Data ? res.Data[0].s_id : '';
          } else {
            let msg = res.Message ? res.Message : "获取园区数据失败！";
            ElMessage.error(msg);
          }
        });
      }
      /**
       * 
       * 统计数据
       * 
       * **/
      const lastEchartQuery = computed(() => {
        return {
          stime: formData.paymentTime[0],
          etime: formData.paymentTime[1],
          scenicId: formData.scenicId,
        };
      });
      /**
       * 
       * 明细数据
       * 
       * **/
      const lastTableQuery = computed(() => {
        return {
          ...queryData.value,
          stime: formData.paymentTime[0],
          etime: formData.paymentTime[1],
          scenicId: formData.scenicId,
        };
      });
      /**
       * 
       * 支付时间选择
       * 
       * **/
      const paymentTimeChange = () => {
        formData.stime = formData.paymentTime[0];
        formData.etime = formData.paymentTime[1];
      }
      /**
       * 
       * 监听园区选择获取门票选项
       * 
       * **/
      watch(
        () => formData.scenicId,
        (v) => {
          if (v) {
            queryData.value.st_id = [];
            BasicApi.getAllTicketData({ s_id: v, status: '2' }).then((res) => {
              if (res.Code === 200) {
                spotOptions.value = res.Data;
              } else {
                let msg = res.Message;
                ElMessage.error(msg);
              }
            });
          }
        },
        {
          deep: true,
          immediate: true,
        }
      );
      /**
       * 
       * 获取支付方式选项
       * 
       * **/
      const getOrderType = () => {
        BasicApi.paywayOptions().then((res) => {
          if (res.Code === 200) {
            orderTypeOptions.value = res.Data;
          } else {
            let msg = res.Message ? res.Message : "获取支付方式失败！";
            ElMessage.error(msg);
          }
        });
      };
      /**
       * 
       * 重置按钮
       * 
       * **/
      function handleReset() {
        formData.scenicId = scenicOptions.value[0].s_id;
        formData.paymentTime = [dayjs().format("YYYY-MM-DD"), dayjs().format("YYYY-MM-DD")];
        queryData.value = {
          st_id: [],
          payway: [],
        };
      }






      function showSelect(flag, tab) {
        //显示支付方式选择框
        isTableDetail.value = flag;
        isTicketOptions.value = tab == '2' || tab == '3';
      }



      // const handleQuery = () => {
      //   showSelect(false);
      //   echartClick.value = false;
      //   showEchart.value = !showEchart.value;
      //   if (showEchart.value) {
      //     queryData.value = {
      //       spotId: [],
      //       payway: [],
      //     };
      //   }
      // };
      // 是否点击图表
      const echartClick = ref(false);
      // 点击了图表-支付方式后跳转明细表
      function paywayClick(data) {
        active.value = 1;
        echartClick.value = true;
        queryData.value.payway = [data];
        queryData.value.st_id = [];
        showEchart.value = false;
      }
      function spotClick(data) {
        active.value = 1;
        echartClick.value = true;
        queryData.value.st_id = [data];
        queryData.value.payway = [];
        showEchart.value = false;
      }
      const scenicTableRef = ref(null);
      function handleExport() {
        scenicTableRef.value.handleExport();
      }
      function handlePrint() {
        scenicTableRef.value.handlePrint();
      }


      onMounted(() => {
        formData.stime = formData.paymentTime[0];
        formData.etime = formData.paymentTime[1];
        getOrderType();
        getAllScenicData();
      });

      return {
        getAllScenicData,
        paymentTimeChange,
        modeTabs,
        active,
        onClickTab,
        scenicOptions,
        handleReset,





        // handleQuery,
        formData,
        spotOptions,
        queryData,
        orderTypeOptions,
        paywayClick,
        spotClick,
        lastTableQuery,
        showEchart,
        handleExport,
        handlePrint,
        scenicTableRef,
        lastEchartQuery,
        showSelect,
        isTableDetail,
        echartClick,
        isTicketOptions,
      };
    },
  };
</script>

<style lang="scss">
  .main-cnt {
    height: auto !important;
  }

  .tabActive {
    background-color: var(--btn-bg-color);
    color: var(--text-white-color);
    border-radius: 20px;
  }

  .tabs {
    color: var(--text-third-color);
  }
</style>